import React from 'react';
import PropTypes from 'prop-types';

import styles from './Iniciativas.module.scss';
import Img from "gatsby-image";
import ExtLink from "./ExtLink";

const Iniciativas = ({background}) => (
    <div className={styles.container}>
        <ExtLink className={styles.anchor} id="iniciativas"/>
        <Img className={styles.background} fluid={background} imgStyle={{objectFit: 'contain'}}
             style={{position: false}}/>
        <div className={styles.wrap}>
            <div className={styles.iniciativas}>
                <h1><span className="emphasis">Iniciativas</span></h1><br/>
                <h2>NOSSOS PASSOS VÊM DE LONGE:
                    ANTIGAS E NOVAS INICIATIVAS
                    ANTIRRACISTAS SOBRE MEMÓRIA
                    NA CIDADE DE SÃO PAULO
                </h2>
                <p><strong>O movimento &quot;SP &eacute; Solo Preto e Ind&iacute;gena&quot; surgiu da iniciativa da
                    vereadora
                    Luana Alves em propor o <ExtLink
                        to="https://www.radarmunicipal.com.br/proposicoes/projeto-de-lei-47-2021"
                        aria-label="Radar Municipal - Projeto de Lei nº 47/2021"
                        title="Radar Municipal - Projeto de Lei nº 47/2021">Projeto de Lei
                        47/2021</ExtLink></strong>, que busca ressignificar e/ou realocar toda homenagem
                    feita a escravocratas e eugenistas na cidade de S&atilde;o Paulo. Este projeto de lei, para
                    al&eacute;m da luta parlamentar, se prop&otilde;e a ser o <strong>est&iacute;mulo a um outro projeto
                        de
                        cidade</strong>. Que n&atilde;o s&oacute; deixe de homenagear a hist&oacute;ria da
                    escravid&atilde;o mas que
                    seja <strong>profundamente antirracista.</strong></p>
                <p>Pretendemos aqui potencializar as iniciativas j&aacute; existentes e propor novas formas de
                    organiza&ccedil;&atilde;o e interven&ccedil;&atilde;o. Entendemos que o parlamento n&atilde;o foi
                    criado para n&oacute;s, n&atilde;o bastando a nossa luta na C&acirc;mara para que algo mude. O
                    SP &eacute; Solo Preto e Ind&iacute;gena &eacute;, portanto, uma constru&ccedil;&atilde;o de
                    diversos <strong>movimentos negros, comunidades ind&iacute;genas, movimentos populares, grupos e
                        coletivos
                        de arte e cultura e pesquisadores</strong> empenhados em <strong>desnaturalizar a
                        hist&oacute;ria</strong> contada apenas pela
                    vis&atilde;o dos aristocratas e valorizar as hist&oacute;rias daqueles e daquelas que
                    constru&iacute;ram essa cidade nas costas e a fazem funcionar diariamente.</p>
                <p><strong>N&atilde;o estamos inventando a roda.</strong> A iniciativa de ressignifica&ccedil;&atilde;o
                    de
                    est&aacute;tuas, monumentos e a altera&ccedil;&atilde;o de nomes de ruas e avenidas que homenageiam
                    algozes da escravid&atilde;o e genocidas negros e ind&iacute;genas &eacute; um movimento
                    &nbsp;<strong>hist&oacute;rico e internacional</strong>. Em muitos a discuss&atilde;o
                    vem &agrave; tona,
                    como quando <ExtLink
                        to="https://g1.globo.com/mundo/noticia/2020/06/07/manifestantes-derrubam-estatua-do-traficante-de-escravos-edward-colston-em-bristol-na-inglaterra.ghtml"
                        title="G1 - Manifestantes derrubam estátua do traficante de escravos Edward Colston em Bristol na Inglaterra"
                        aria-label="G1 - Manifestantes derrubam estátua do traficante de escravos Edward Colston em Bristol na Inglaterra"
                    >promoveu a derrubada a est&aacute;tua de um traficante de escravos na Inglaterra</ExtLink> em
                    rea&ccedil;&atilde;o &agrave; morte de George Floyd nos Estados Unidos. Representa o
                    direito &agrave; cidade, a desnaturaliza&ccedil;&atilde;o da hist&oacute;ria contada pelos
                    poderosos.</p>

                <p><strong>N&atilde;o, n&atilde;o &eacute; poss&iacute;vel apagar a hist&oacute;ria.</strong> N&atilde;o
                    se trata de
                    fingir que esses algozes existiram. Se trata do direito &agrave; plena circula&ccedil;&atilde;o
                    espa&ccedil;os urbanos sem que sejamos constrangidos por brutais figuras hist&oacute;ricas que
                    impuseram a viol&ecirc;ncia, a nega&ccedil;&atilde;o da humanidade de pessoas negras traficadas e
                    escravizadas e arquiteteram um verdadeiro genoc&iacute;dio ind&iacute;gena. Se trata de contar
                    tamb&eacute;m a hist&oacute;ria brutalmente apagada dos nossos ancestrais. Como questiona a
                    escritora nigeriana Chimamanda Ngozi Adichie, <ExtLink
                        to="https://www.youtube.com/watch?v=D9Ihs241zeg&ab_channel=TED"
                        aria-label="YouTube - O perigo de uma história Única | Chimamanda Ngozi Adichie"
                        title="YouTube - O perigo de uma história Única | Chimamanda Ngozi Adichie">qual o perigo de uma
                        hist&oacute;ria &uacute;nica?</ExtLink></p>

                <p><strong>Em S&atilde;o Paulo, muitas iniciativas t&ecirc;m como objetivo exigir justi&ccedil;a racial
                    neste solo, </strong>como o <ExtLink
                    to="https://www.hypeness.com.br/2020/06/erica-malunguinho-apresenta-projeto-para-a-retirada-de-estatuas-de-escravocratas-em-sp/"
                    aria-label="Hypeness - Erica Malunguinho apresenta projeto para a retirada de estátuas de escravocratas em SP"
                    title="Hypeness - Erica Malunguinho apresenta projeto para a retirada de estátuas de escravocratas em SP">PL
                    404/2020</ExtLink> da companheira Erica Malunguinho a n&iacute;vel estadual, o movimento Zumbi
                    resiste que pretende alterar o nome da <ExtLink
                        to="https://midia4p.cartacapital.com.br/movimento-quer-mudar-nome-da-rua-que-homenageia-o-algoz-de-zumbi-dos-palmares/"
                        aria-label="Mídia 4P - Carta Capital - Movimento quer mudar nome da rua que homenageia o algoz de Zumbi dos Palmares"
                        title="Mídia 4P - Carta Capital - Movimento quer mudar nome da rua que homenageia o algoz de Zumbi dos Palmares">Rua
                        Jorge Velho</ExtLink>, e tantas outras a&ccedil;&otilde;es frutos
                    da mobiliza&ccedil;&atilde;o de diversos coletivos em diversos territ&oacute;rios. <strong>Com o Projeto de
                    Lei 47/2021 - chamado por n&oacute;s de &quot;PL SP &eacute; Solo Preto e
                        ind&iacute;gena&quot; - pretendemos que surjam muitas outras iniciativas</strong>, n&atilde;o s&oacute; no
                    parlamento, mas tamb&eacute;m nas ruas. Sob a bandeira de que SP &eacute; Solo Preto e
                    Ind&iacute;gena pensaremos e construiremos uma nova cidade.</p>

                <p>Tem um prov&eacute;rbio africano que diz que <strong>enquanto os le&otilde;es n&atilde;o contarem
                    suas pr&oacute;prias hist&oacute;rias, a hist&oacute;ria das ca&ccedil;adas continuar&atilde;o
                    glorificando o ca&ccedil;ador</strong>. Agora n&oacute;s, que somos maioria do povo
                    brasileiro, <strong>queremos contar a nossa hist&oacute;ria.</strong></p>
            </div>
        </div>
    </div>
);

Iniciativas.propTypes = {
    background: PropTypes.object,
    iniciativas: PropTypes.array
};

export default Iniciativas;
