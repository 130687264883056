import React from 'react';
import PropTypes from 'prop-types';

import styles from './Manifesto.module.scss';
import {Form} from "@evag/ec-react";
import CampaignForm from "./CampaignForm";
import CampaignFeedback from "./CampaignFeedback";
import Img from "gatsby-image";
import LeiaMais from "./LeiaMais";

const Manifesto = ({share, background}) => (
    <div className={styles.container}>
        <a className={styles.anchor} id="manifesto"></a>
        <Img className={styles.background} fluid={background} imgStyle={{objectFit: 'contain'}}
             style={{position: false}}/>
        <div className={styles.wrap}>
            <div className={styles.manifesto}>
                <h1><span className="emphasis">O Manifesto: Assine!</span></h1><br/>
                <p><strong>O BRASIL &Eacute; O PA&Iacute;S MAIS NEGRO FORA DO CONTINENTE AFRICANO</strong> Somos uma na&ccedil;&atilde;o
                    atravessada econ&ocirc;mica, pol&iacute;tica, social e psicologicamente pela experi&ecirc;ncia
                    colonial que durou s&eacute;culos de escraviza&ccedil;&atilde;o de africanos, violentamente
                    traficados. A ra&ccedil;a foi uma inven&ccedil;&atilde;o para justificar e concretizar um projeto de
                    domina&ccedil;&atilde;o. N&atilde;o existe, portanto, cidad&atilde;o que n&atilde;o reproduza de
                    alguma forma o racismo que estrutura o capitalismo brasileiro hoje. Por isso, o movimento negro
                    brasileiro tomou a tarefa de se rebelar contra essa estrutura. Desde o desembarque do primeiro
                    africano traficado no pa&iacute;s perdura um longo e complexo processo de resist&ecirc;ncia.
                    Portanto, a problem&aacute;tica da ra&ccedil;a &eacute; tensionada n&atilde;o apenas pelo racismo
                    enquanto opress&atilde;o, mas tamb&eacute;m pelo antirracismo enquanto pr&aacute;tica.</p>

                <p><strong>SP &Eacute; S&Oacute;LO PRETO E IND&Iacute;GENA</strong> Dotada de um violento processo de apagamento da
                    mem&oacute;ria negra e ind&iacute;gena, a cidade de S&atilde;o Paulo &eacute; hoje a cidade mais
                    negra do pa&iacute;s, em n&uacute;mero absoluto. Vivem aqui mais de 4 milh&otilde;es de negras e
                    negros, mesmo com toda a politica de persegui&ccedil;&atilde;o, e tortura que mata e expulsa as
                    popula&ccedil;&otilde;es racializadas cada vez para mais distante. A popula&ccedil;&atilde;o negra
                    foi a respons&aacute;vel por subir os pr&eacute;dios da cidade, mas sempre esteve &agrave;s margens,
                    inclusive nos tempos em que as margens eram o que hoje &eacute; o centro. Nossa hist&oacute;ria e
                    nossos territ&oacute;rios foram embranquecidos. O Quilombo da Saracura, hoje &eacute; conhecido pelo
                    Bixiga das cantinas italianas. A Barra Funda, do antigo Largo da Banana, ber&ccedil;o do samba
                    paulistano, &eacute; comumente retratada como o bairro dos oper&aacute;rios europeus, mas nunca pela
                    tradi&ccedil;&atilde;o afro-brasileira; a Liberdade, o pelourinho de S&atilde;o Paulo e o largo da
                    forca, tem seu nome, fruto da resist&ecirc;ncia abolicionista, apenas relacionado &agrave; cultura
                    de alguns povos asi&aacute;ticos.</p>

                <LeiaMais>
                    <p>Ou mesmo o apagamento sobre a hist&oacute;ria negra no bairro do Jabaquara onde j&aacute; existiu
                        o &ldquo;Quilombo da Passagem&rdquo; no S&iacute;tio da Ressaca, territ&oacute;rio que sofreu em
                        1969 sua &uacute;ltima desapropria&ccedil;&atilde;o para a constru&ccedil;&atilde;o do
                        metr&ocirc;.</p>

                    <p>Ao passo que a hist&oacute;ria do povo negro em S&atilde;o Paulo foi apagada, a mem&oacute;ria
                        hist&oacute;rica paulistana foi constru&iacute;da homenageando os algozes do nosso povo negro e
                        ind&iacute;gena, como os bandeirantes,respons&aacute;veis pela grande parte do genocidio da
                        popula&ccedil;&atilde;o indgena, seja com est&aacute;tuas ou como as de Borba Gato no bairro de
                        Santo Amaro. O monumento &agrave;s Bandeiras no Parque Ibirapuera. A est&aacute;tua de
                        Anhanguera no parque Trianon ou em nomes de ruas, como a Rua Jorge Velho, bandeirante que matou
                        Zumbi dos Palmares.</p>

                    <p><strong>DEBATER MEM&Oacute;RIA &Eacute; DEBATER A NOSSA VIDA CONCRETA.</strong> Refletir sobre as
                        cidades que
                        vivemos n&atilde;o &eacute; uma abstra&ccedil;&atilde;o. Ao
                        contr&aacute;rio, &eacute; trazer &agrave; tona as hist&oacute;rias do povo que construiu e
                        botou essa cidade de p&eacute;, mas que foi violentamente assassinado. Para que um
                        genoc&iacute;dio se consolide, &eacute; necess&aacute;rio esquec&ecirc;-lo. E para que uma
                        viol&ecirc;ncia estrutural permane&ccedil;a, &eacute; necess&aacute;rio neg&aacute;-la. A
                        mem&oacute;ria, portanto, &eacute; pol&iacute;tica.</p>

                    <p> O pouco da hist&oacute;ria da escravid&atilde;o e do racismo brasileiros que
                        nos &eacute; contada vem como algo distante e pela vis&atilde;o dos opressores, sem
                        associa&ccedil;&atilde;o com os dias de hoje. E o racismo, que forma a subjetividade de todos os
                        brasileiros, &eacute; constantemente questionado pela ideologia da democracia racial &ndash; de
                        que neste territ&oacute;rio, todas as ra&ccedil;as convivem harmoniosamente. O resultado da
                        nega&ccedil;&atilde;o dessa estrutura se revelam na realidade como ela &eacute;: a cada 23
                        minutos morre um jovem negro; 68% das mulheres assassinadas s&atilde;o negras; negros s&atilde;o
                        75% entre os mais pobres e brancos s&atilde;o 70% entre os mais ricos; 4% dos parlamentares
                        brasileiros s&atilde;o negros; entre outras estat&iacute;sticas lament&aacute;veis para um
                        pa&iacute;s como o Brasil do s&eacute;culo XXI.</p>

                    <p><strong>A NOSSA LUTA &Eacute; INCONTORN&Aacute;VEL, PERMANENTE E DEPENDE DE TODES.</strong></p>

                    <p> Em 1978, nas escadarias do Theatro Municipal de S&atilde;o Paulo, o Movimento Negro Unificado
                        lan&ccedil;ava um manifesto denunciando a falsa aboli&ccedil;&atilde;o, o mito da democracia
                        racial e pedindo o fim da viol&ecirc;ncia policial. Esse epis&oacute;dio que aconteceu na nossa
                        cidade, no contexto da ditadura militar, &eacute; um dos marcos na hist&oacute;ria da
                        resist&ecirc;ncia do povo brasileiro e se insere dentro de uma agenda de lutas mais ampla do
                        per&iacute;odo, quando pensamos na resist&ecirc;ncia antirracista e anticolonial a n&iacute;vel
                        internacional Desde os movimentos por liberta&ccedil;&atilde;o dos pa&iacute;ses africanos
                        at&eacute; a luta por direitos civis nos Estados Unidos. Essa luta hist&oacute;rica reverbera
                        at&eacute; hoje, tornando incontorn&aacute;vel a pauta do racismo na sociedade, inclusive cada
                        vez mais presente na grande m&iacute;dia. Como a cidade de S&atilde;o Paulo se comporta diante
                        de tal efervesc&ecirc;ncia? Onde est&atilde;o as est&aacute;tuas, ruas, pra&ccedil;as e
                        pr&eacute;dios homenageando homens e mulheres negras e ind&iacute;genas que enfrentaram seus
                        opressores? Que constru&iacute;ram importantes inven&ccedil;&otilde;es? Que escreveram
                        importantes livros? Que s&atilde;o refer&ecirc;ncia nas artes e na cultura? Que lutaram contra o
                        racismo e o machismo? Como questiona a escritora nigeriana Chimamanda Adichie: Qual o perigo de
                        uma hist&oacute;ria &uacute;nica? Repensar a nossa cidade e reivindicar a
                        valoriza&ccedil;&atilde;o e visibilidade da mem&oacute;ria negra &eacute; lutar por uma cidade
                        mais democr&aacute;tica. E essa responsabilidade &eacute; de todes e todas e todos aqueles que
                        sonham com uma cidade melhor.</p>

                    <p><strong>S&atilde;o Paulo &eacute; solo preto e ind&iacute;gena!</strong></p>

                    <p><strong>SEJA PARTE DESSA LUTA!<br/>ASSINE O MANIFESTO!</strong></p>
                    <hr/>
                    <p><strong>O QUE QUEREMOS:</strong></p>
                    <ul>
                        <li>A Substitui&ccedil;&atilde;o de nomes de ruas, avenidas e rodovias, imagens e
                            s&iacute;mbolos da cidade que homenageiam escravocratas ou/e eugenistas por
                            refer&ecirc;ncias hist&oacute;ricas que marcam a luta, resist&ecirc;ncia e
                            constru&ccedil;&atilde;o da cidade de S&atilde;o Paulo.
                        </li>
                        <li>Retirada e realoca&ccedil;&atilde;o de monumentos e est&aacute;tuas que homenageiam
                            escravocratas ou/e eugenistas e substitui&ccedil;&atilde;o por refer&ecirc;ncias
                            hist&oacute;ricas que marcam a luta, resist&ecirc;ncia e constru&ccedil;&atilde;o da cidade
                            de S&atilde;o Paulo;
                        </li>
                        <li>Reconhecimento e tombamento como patrim&ocirc;nio hist&oacute;rico da cidade, de
                            territ&oacute;rios que carregam significados de luta e resist&ecirc;ncia em S&atilde;o
                            Paulo.
                        </li>
                    </ul>

                    <div className={styles.citations}>
                        <p>&#8230;N&oacute;s estamos al&eacute;m desse mapa, n&atilde;o cabemos na tua ampulheta,
                            n&atilde;o
                            vestimos tampouco esta roupa&#8230;<br/>Salve geral &mdash; Alafia</p>

                        <p>&#8230; Cada ligadura deste ch&atilde;o, ostenta orgulho e faz gente querer voar, Bela vista,
                            bela vista, bela vista, onde 13 de maio n&atilde;o cruza com aboli&ccedil;&atilde;o,
                            quilombo
                            urbano de casar&atilde;o em casar&atilde;o, cada rachadura marginal desse sapato engraxado,
                            alvinegro, debaixo do mais belo mestre sala, cada esquina esculachada que
                            d&aacute; nome &agrave; reden&ccedil;&atilde;o&#8230;<br/>Saracura &mdash; Alafia</p>

                        <p>Eu classifico S&atilde;o Paulo assim: O Pal&aacute;cio &eacute; a sala de visita. A
                            Prefeitura &eacute; a sala de jantar e a cidade &eacute; o jardim. E a favela &eacute; o
                            quintal
                            onde jogam os lixos.<br/>Carolina Maria de Jesus</p>
                    </div>
                </LeiaMais>

            </div>

            <div id="sign" className={styles.signatures}>
                <div className={styles.signaturesTitle}>
                    <h1>
                        <span className="emphasis">Assine </span><br/>
                        <span className="emphasis">o Manifesto </span><br/>
                        <span className="emphasis">e Compartilhe!</span>
                    </h1>
                </div>
                <Form
                    id="manifesto-form"
                    className={styles.signaturesForm}
                    forms={[
                        CampaignForm,
                        {
                            ...CampaignFeedback,
                            title: 'Obrigada por assinar!',
                            description: share,
                        },
                    ]}
                />
            </div>
        </div>
    </div>
);

Manifesto.propTypes = {
    share: PropTypes.string,
    background: PropTypes.object
};

export default Manifesto;
