import React from 'react';
import PropTypes from 'prop-types';

import './Layout.scss';

import styles from './Carousel.module.scss';

import Page from '../svg/page.svg';

class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.children = props.children;

        let itemWidth = 268;
        let arrowWidth = 60;
        let containerWidth = 900;
        if (typeof window !== "undefined") {
            containerWidth = window.innerWidth - 30;
        }

        this.state = {
            total: Math.floor((this.children.length * itemWidth) / (containerWidth - 2 * arrowWidth)),
            current: 0
        }

        this.content = React.createRef();
    }

    getNumPages() {
        return Math.floor(this.content.current.scrollWidth / this.content.current.clientWidth);
    }

    previous() {
        if (this.content && this.content.current) {
            let current = this.state.current;
            this.content.current.scrollLeft = this.content.current.clientWidth * --current;

            let total = this.getNumPages();
            this.setState({total: total, current: Math.max(current, 0)});
        }
    }

    next() {
        if (this.content && this.content.current) {
            let current = this.state.current;
            this.content.current.scrollLeft = this.content.current.clientWidth * ++current;

            let total = this.getNumPages();
            this.setState({total: total, current: Math.min(current, total)});
        }
    }

    isFirst() {
        return this.state.current === 0;
    }

    isLast() {
        return this.state.current === this.state.total;
    }

    generatePages() {
        const pages = Array.from(Array(this.state.total + 1).keys());
        return pages.map(page => {
            return (<Page className={(this.state.current === page) ? styles.currentPage : ""}/>);
        });
    }

    onScroll() {
        let total = this.getNumPages();
        console.log("Scroll: " + this.content.current.scrollLeft + " | Width: " + this.content.current.scrollWidth);
        let current = this.content.current.scrollLeft / this.content.current.clientWidth;
        console.log(current);
        if (this.content.current.scrollWidth - this.content.current.scrollLeft > this.content.current.clientWidth) {
            this.setState({total: total, current: Math.floor(current)});
        } else {
            this.setState({total: total, current: Math.floor(current + 1)});
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.carousel + " "}>
                    <div className={styles.seta + (this.isFirst() ? " " + styles.disabled : "")}
                         onClick={() => this.previous()} disabled={this.isFirst()}>{"<"}</div>
                    <div id={"carousel-content"} className={styles.content} ref={this.content} onScroll={() => this.onScroll()}>{
                        this.children
                    }</div>
                    <div className={styles.seta + (this.isLast() ? " " + styles.disabled : "")}
                         onClick={() => this.next()} disabled={this.isLast()}>{">"}</div>
                </div>
                <div className={styles.pagination}>{this.generatePages()}
                </div>
            </React.Fragment>
        )
    }
};

Carousel.propTypes =
    {
        children: PropTypes.node.isRequired,
    }
;

export default Carousel;
