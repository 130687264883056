import React from 'react';
import PropTypes from 'prop-types';

import styles from './Apoiadores.module.scss';
import Img from "gatsby-image";
import Carousel from "./Carousel";

const Apoiadores = ({data}) => (
    <div className={styles.container}>
        <a className={styles.anchor} id="apoiadores"></a>
        <div className={styles.wrap}>
            <h1><span className="emphasis">Quem constrói e apoia</span></h1><br/>
        </div>
        <div className={styles.apoiadores}>
            <Carousel>
                {data.map((item) => {
                    return <div className={styles.apoiador}>
                        <Img fluid={item.photo} className={styles.apoiadorImage}/>
                        <p>{item.name}<br/><span className={styles.organizacao}>{item.organizacao}</span></p>
                    </div>
                })
                }
            </Carousel>
        </div>
    </div>
);

Apoiadores.propTypes = {
    data: [PropTypes.array, PropTypes.node.isRequired]
};

export default Apoiadores;
