import React from 'react';
import PropTypes from 'prop-types';

import styles from './Formacao.module.scss';
import Img from "gatsby-image";

const Formacao = ({background, logos}) => (
    <div className={styles.container}>
        <a className={styles.anchor} id="formacao"/>
        <Img className={styles.background} fluid={background} imgStyle={{objectFit: 'contain'}}
             style={{position: false}}/>
        <div className={styles.wrap}>
            <div className={styles.formacao}>
                <h1><span className="emphasis">Formação</span></h1><br/>
                <h3>CURSO DE EXTENSÃO • GRATUITO • ONLINE</h3>
                <h2>SOLO PRETO: MEMÓRIA<br/>
                    E JUSTIÇA RACIAL<br/>
                    NA CIDADE DE SÃO PAULO<br/>
                </h2>
                <h3>Objetivos</h3>
                <ul>
                    <li>
                        Contextualizar a história do Brasil, atravessada pelo fenômeno colonial e pela invenção da raça,
                        apontando para como a memória histórica sobre personagens e fenômenos é forjada pelo
                        tensionamento entre racismo e antirracismo.
                    </li>
                    <li>
                        Identificar os territórios da cidade de São Paulo cuja formação histórica é perpassada pela
                        presença de populações de africanos escravizados, quilombolas, populações indígenas,
                        organizações de negros e negras, etc;
                    </li>
                    <li>
                        Apontar a importância da memória na luta antirracista; a importância do antirracismo na
                        construção da memória da cidade; a importância do antirracismo como uma pauta democrática,
                        cidadã, de responsabilidade prática de todes.
                    </li>
                </ul>
                <h3>Ementa</h3>
                <ul>
                    <li>
                        Em breve!
                    </li>
                </ul>

                <div className={styles.footer}>
                    <h1 className={styles.cta}><span className="emphasis-white">Inscrições: Em breve!</span></h1><br/>
                    <div className={styles.logo}>
                        <Img fluid={logos} imgStyle={{objectFit: 'contain', position: 'relative', padding: '0 auto', margin: '0 auto'}}
                             style={{position: false}}/>
                    </div>
                </div>

            </div>
        </div>
    </div>
);

Formacao.propTypes = {
    background: PropTypes.object
};

export default Formacao;
