import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Menu from '../components/Menu';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Manifesto from "../components/Manifesto";
import Apoiadores from "../components/Apoiadores";
import Iniciativas from "../components/Iniciativas";
import Formacao from "../components/Formacao";
import SocialNetworks from "../components/SocialNetworks";
import Img from "gatsby-image";

export default function render({data}) {
    CampaignForm.channelId = data.site.meta.channel;

    return (
        <Layout>
            <SEO title={data.site.meta.title} ogimage={data.ogImage.childImageSharp.fluid}/>

            <Menu
                logo={data.logo.childImageSharp.fluid}
                items={[
                    {to: "/#manifesto", name: "Manifesto: Assine"},
                    {to: "/contribuicoes", name: "Contribuições"},
                    {to: "/#iniciativas", name: "Iniciativas"},
                    {to: "/pressione", name: "Pressione"},
                ]}
                instagram={{
                    logo: data.instagram.childImageSharp.fluid,
                    user: "solopretoeindigena"
                }}
                site={data.site.meta}
            />

            <Hero
                left="Memória e justiça racial na cidade de São Paulo."
                logo={data.hero.childImageSharp.fluid}
                right="Assine.
                Compartilhe.
                Construa."
                channel={data.site.meta.channel}
            />

            <Manifesto share={data.site.meta.description}
                       background={data.manifesto.childImageSharp.fluid}/>

            <Apoiadores data={[
                {
                    name: "Luana Alves",
                    organizacao: "Líder da bancada do PSOL-SP",
                    photo: data.luana.childImageSharp.fluid
                }, {
                    name: "Rede Emancipa",
                    organizacao: "Movimento Social de Educação Popular",
                    photo: data.emancipa.childImageSharp.fluid
                },
                {
                    name: "Silvio de Almeida",
                    organizacao: "Instituto Luiz Gama",
                    photo: data.silvioDeAlmeida.childImageSharp.fluid
                },
                {
                    name: "Chirley Pankará",
                    organizacao: "Mandata Ativista",
                    photo: data.chirley.childImageSharp.fluid
                },
                {
                    name: "Abilio Ferreira",
                    organizacao: "Instituto Tebas",
                    photo: data.abilio.childImageSharp.fluid
                },
                {
                    name: "Mestra Janja",
                    organizacao: "Grupo Nzinga",
                    photo: data.janja.childImageSharp.fluid
                },
                {
                    name: "Erika Hilton",
                    organizacao: "Vereadora pelo PSOL SP",
                    photo: data.erika.childImageSharp.fluid
                },
                {
                    name: "Milton Barbosa",
                    organizacao: "Movimento Negro Unificado",
                    photo: data.milton.childImageSharp.fluid
                },
                {
                    name: "Clarice Pankararu",
                    organizacao: "S.O.S. Pankararu do Real Parque",
                    photo: data.claricePankararu.childImageSharp.fluid
                },
                {
                    name: "Jairo Pereira",
                    organizacao: "Aláfia",
                    photo: data.jairo.childImageSharp.fluid
                },
                {
                    name: "Baby Amorim",
                    organizacao: "Ilú Obá De Min",
                    photo: data.baby.childImageSharp.fluid
                },
                {
                    name: "Elaine Mineiro",
                    organizacao: "Mandato Quilombo Periférico",
                    photo: data.elaine.childImageSharp.fluid
                },
                {
                    name: "Geledés",
                    organizacao: "Instituto da Mulher Negra",
                    photo: data.geledes.childImageSharp.fluid
                },
                {
                    name: "MNU",
                    organizacao: "Movimento Negro Unificado",
                    photo: data.mnu.childImageSharp.fluid
                },
                {
                    name: "IPEAFRO",
                    organizacao: "Instituto de Pesquisas e Estudos Afro-Brasileiros",
                    photo: data.ipeafro.childImageSharp.fluid
                },
                {
                    name: "ABPN",
                    organizacao: "Associação Brasileira de Pesquisadores/as Negros/as",
                    photo: data.bpn.childImageSharp.fluid
                },
                {
                    name: "MMNSP",
                    organizacao: "Marcha das Mulheres Negras de São Paulo",
                    photo: data.marcha.childImageSharp.fluid
                },
                {
                    name: "SaraUpovo",
                    organizacao: "Cultura, educação e historiografia",
                    photo: data.sarauPovo.childImageSharp.fluid
                },
                {name: "Mestre Tião Carvalho", organizacao: "Grupo Cupuaçu", photo: data.tiao.childImageSharp.fluid},
                {name: "Mestre Dinho Nascimento", organizacao: "", photo: data.dinho.childImageSharp.fluid},
                {name: "Instituto Vladimir Herzog", organizacao: "", photo: data.herzog.childImageSharp.fluid},
                {name: "Coletivo Som Na Praça", organizacao: "", photo: data.snp.childImageSharp.fluid},
                {name: "Feminine H-Fi", organizacao: "", photo: data.feminine.childImageSharp.fluid},
                {name: "Portal RAS", organizacao: "Associação Nacional do Reggae", photo: data.ras.childImageSharp.fluid},
                {name: "Coletivo Cartografia Negra", organizacao: "", photo: data.cartografia.childImageSharp.fluid},
                {name: "ANATORG", organizacao: "Associação Nacional das Torcidas Organizadas", photo: data.anatorg.childImageSharp.fluid},
                {name: "Programa Já regou suas plantas?", organizacao: "98,9 FM", photo: data.regou.childImageSharp.fluid}
            ]}/>

            <Iniciativas
                background={data.iniciativas.childImageSharp.fluid}
            />

            <Formacao
                background={data.formacao.childImageSharp.fluid}
                logos={data.formacaoLogos.childImageSharp.fluid}
            />

            <footer className="footer">
                <div className="wrap">
                    <div className="description">
                        <div className="info">
                            <h1>Uma iniciativa do mandato da vereadora Luana Alves</h1>
                        </div>
                        <div className="social-networks">
                            <SocialNetworks
                                twitter="luanapsol"
                                facebook="luanapsol"
                                instagram="luanapsol"
                                youtube="channel/UClZyv3izFgsogtxHI1Z9Cxw"
                                email="comunicaluanaalves@gmail.com"
                            />
                        </div>
                    </div>
                    <div className="logos">
                        <Img className="logo" alt="Punho - SP é Solo Preto!"
                             imgStyle={{
                                 objectFit: 'contain'
                             }}
                             style={{position: false}}
                             fluid={data.punho.childImageSharp.fluid}/>
                    </div>
                </div>
            </footer>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "SPSOLO-LOGO.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    manifesto: file(relativePath: { eq: "pankararu.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    logo: file(relativePath: { eq: "pretoindigena.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    ogImage: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    iniciativas: file(relativePath: { eq: "iniciativas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    formacao: file(relativePath: { eq: "curso.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    formacaoLogos: file(relativePath: { eq: "formacao-logos.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    punho: file(relativePath: { eq: "punho-footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    luana: file(relativePath: { eq: "apoiadores/luana alves.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    bpn: file(relativePath: { eq: "apoiadores/BPN.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    elaine: file(relativePath: { eq: "apoiadores/Elaine.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    geledes: file(relativePath: { eq: "apoiadores/geledés.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    ipeafro: file(relativePath: { eq: "apoiadores/IPEAFRO.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    marcha: file(relativePath: { eq: "apoiadores/marcha.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    mnu: file(relativePath: { eq: "apoiadores/MNU.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    sarauPovo: file(relativePath: { eq: "apoiadores/Saraupovo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    silvioDeAlmeida: file(relativePath: { eq: "apoiadores/silvio almeida.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    abilio: file(relativePath: { eq: "apoiadores/abilio.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    baby: file(relativePath: { eq: "apoiadores/baby.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    chirley: file(relativePath: { eq: "apoiadores/chirley.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    claricePankararu: file(relativePath: { eq: "apoiadores/clarice pankararu.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    erika: file(relativePath: { eq: "apoiadores/erika.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    jairo: file(relativePath: { eq: "apoiadores/jairo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    janja: file(relativePath: { eq: "apoiadores/janja.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    milton: file(relativePath: { eq: "apoiadores/milton.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    emancipa: file(relativePath: { eq: "apoiadores/rede emancipa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    tiao: file(relativePath: { eq: "apoiadores/mestre tiao carvalho.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    dinho: file(relativePath: { eq: "apoiadores/mestre dinho.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    herzog: file(relativePath: { eq: "apoiadores/herzog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    snp: file(relativePath: { eq: "apoiadores/coletivo SNP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    feminine: file(relativePath: { eq: "apoiadores/feminine hifi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    ras: file(relativePath: { eq: "apoiadores/PORTAL RAS.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    cartografia: file(relativePath: { eq: "apoiadores/cartografia negra.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    anatorg: file(relativePath: { eq: "apoiadores/anatorg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    regou: file(relativePath: { eq: "apoiadores/ja regou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`;
